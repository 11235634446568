// Copyright (C) CELSOS. All rights reserved.
import React, { useEffect, useState } from 'react';

import { Typography } from '@mui/material';

import '../styles.css'; // Asegúrate de tener este archivo de estilo

const TextFadeIn = ({ text }) => {
  const [visibleChars, setVisibleChars] = useState(Array(text.length).fill(false));
  const [coloredChars, setColoredChars] = useState(Array(text.length).fill(false));

  useEffect(() => {
    const timeouts = text.split('').map((_, index) => {
      const delay = Math.random() * 2000; // Genera un retraso aleatorio hasta 2 segundos
      return setTimeout(() => {
        setVisibleChars((prev) => {
          const newVisibleChars = [...prev];
          newVisibleChars[index] = true;
          return newVisibleChars;
        });
        // Otra llamada para cambiar el color
        setTimeout(() => {
          setColoredChars((prev) => {
            const newColoredChars = [...prev];
            newColoredChars[index] = true;
            return newColoredChars;
          });
        }, 1000); // El tiempo que toma para el fade in antes de cambiar el color
      }, delay);
    });

    // Cleanup timeouts on component unmount
    return () => timeouts.forEach(timeout => clearTimeout(timeout));
  }, [text.length]);

  return (
    <Typography
      sx={{ 
        fontSize: '250%', 
        fontFamily: 'silkaregular', 
        flexGrow: 0, 
        paddingTop: '1%', 
        textAlign: 'bottom', 
        mr: 2 
      }}
      color="white"
    >
      <strong>
      {text.split('').map((char, index) => (
        <span
          key={index}
          className={visibleChars[index] ? 'fade-in' : ''}
          style={{ color: coloredChars[index] ? '#656aff' : '#00000700' }}
        >
          {char}
        </span>
      ))}
      </strong>
      <> takes you further.</>
    </Typography>
  );
};

export default TextFadeIn;
