// Copyright (C) CELSOS. All rights reserved.
import { createTheme } from '@mui/material/styles';

const Theme = createTheme({
  palette: {
    primary: {
      main: '#656aff', // Color principal
    },
    secondary: {
      main: '#1b1b49', // Color secundario
    },
    black:{
        main: '#000007'
    },
    yt:{
        main: '#ffffff'
    }
  },
});

export default Theme;