// Copyright (C) CELSOS. All rights reserved.
import React from 'react';
import axios from 'axios';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import RoundedTypography from './RoundedTypography';
import '../App.css';
import { Button } from '@mui/material';


const TopBar = () => {
  const delSession = async () => {
    const response = await axios.delete('https://app.celsospv.com/api/del_session', { withCredentials: true });
    window.location.reload();
  }
  return (
    <React.Fragment>
      <div style={{ backgroundColor: '#656aff', height: '5px', width: '100%' }} />
        <AppBar position="static" color="black" sx={{ height:'100px' }}>
          <Toolbar>

            {/* Webpage Title */}
            <img src="/Logotipo-CELSOS-21.png" onClick={delSession} alt="My Logo" style={{ height: 54, marginTop:24, marginLeft: 43,  cursor: 'pointer' }} /> {/* Añadir imagen */}
            <RoundedTypography padding="2px" fontSize="100%" color="white" borderRadius="7px" marginTop= "10">
                Insight AI
            </RoundedTypography>
            <Box sx={{ flexGrow: 1 }} />
            <a href="https://www.celsospv.com/" target='_blank' rel="noreferrer" className="darken-hover" style={{ textDecoration: 'none' }}>
              <Typography sx={{ fontSize:'140%', fontFamily:'silkaregular', flexGrow: 0, paddingTop: '20px', textAlign: 'bottom', mr: 2 }} color="white">
                  Visit our website
              </Typography>
            </a>
        </Toolbar>
        </AppBar>
    </React.Fragment>
  );
}

export default TopBar;