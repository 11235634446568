// Copyright (C) CELSOS. All rights reserved.
import React, { useState } from 'react';

import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const InputFileUpload = ({ setFiles }) => {
  const [dragOver, setDragOver] = useState(false);

  const handleFileChange = (event) => {
    const files = event.target.files;
    setFiles(files);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    setFiles(files);
    setDragOver(false);
  };

  
  return (
    <div>
      <div
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        style={{
          width: '80%',
          height: '65%',
          border: dragOver ? '2px dashed #000' : '2px dashed #ccc',
          padding: '20px',
          borderRadius: '8px',
          textAlign: 'center',
          marginTop:'35%',
          margin: 'auto',
        }}
      >
        <Button
          component="label"
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
          sx={{ marginBottom: '10px' }}
        >
          <Typography color="white" sx={{ fontSize: '150%', fontFamily: 'silkaregular' }}>
          Upload Image
        </Typography>
          <VisuallyHiddenInput type="file" onChange={handleFileChange} accept="image/png, image/jpeg, image/jpg" />
        </Button>
        <Typography color="white" sx={{ fontSize: '120%', fontFamily: 'silkaregular' }}>
          or drag and it here
        </Typography>
      </div>
    </div>
  );
};

export default InputFileUpload;
