// Copyright (C) CELSOS. All rights reserved.
import React, { useState } from 'react';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Typography } from '@mui/material';

import './ImageCarousel.css';


const ImageCarousel = ({ images, scanning }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleOnChange = (index) => {
    setCurrentIndex(index);
  };
  const TransformUrl = (imageUrl) => {
    imageUrl = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
    return imageUrl.split('?')[0]
  }

  return (
    <div className="image-carousel-container">
      <Carousel
        showThumbs={true}
        useKeyboardArrows={true}
        emulateTouch={true}
        showStatus={false}
        dynamicHeight={false}
        showIndicators={true}
        className="image-carousel"
        renderThumbs={() =>
          images.map((imageUrl, index) => (
            <img
              key={index}
              src={`https://app.celsospv.com/api/${imageUrl}`}
              alt={`thumb-${index}`}
              className={`carousel-thumb ${index === currentIndex ? 'selected' : ''}`}
            />
          ))
        }
        onChange={handleOnChange}
      >
        {images.map((imageUrl, index) => (
          <div key={index} className={`carousel-slide ${scanning ? 'scan-effect active' : 'scan-effect'}`}>
            <img src={`https://app.celsospv.com/api/${imageUrl}`} alt={`slide-${index}`} />
            <Typography align='left' sx= {{ backgroundColor:'#000007', color:'#656CF6',  fontFamily:'silkaregular'}}>{TransformUrl(imageUrl)}</Typography>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ImageCarousel;
