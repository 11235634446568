// Copyright (C) CELSOS. All rights reserved.
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { ThemeProvider } from '@mui/material/styles';
import { Box, LinearProgress, Typography, Modal, Checkbox, FormControlLabel } from '@mui/material';
import Button from '@mui/material/Button';

import './App.css';
import './stylesheet.css';
import ImageCarousel from './components/ImageCarousel';
import InputFileUpload from './components/InputFileUpload';
import RandomTextEffect from './components/TextFadeIn';
import Theme from './components/Theme';
import TopBar from './components/TopBar';

function App() {
  const [images, setImages] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [uploading, setUploading] = useState(false);
  const [scanning, setScanning] = useState(false);
  const [formData, setFormData] = useState([]);
  const [sessionActive, setSessionActive] = useState(false);
  const [open, setOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
    sessionStorage.setItem("open", false)
    console.log("en open hay:", sessionStorage.getItem("open"))
  };

  useEffect(() => {
    const axiosInterceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 440) {  // Error 440 indicates session expired
          alert("Your session has expired. The page will reload to re-establish the session.");
          window.location.reload(); // Reload the page to re-establish session
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(axiosInterceptor); // Cleanup interceptor when component unmounts
    };
  }, []);

  useEffect(() => {
      const checkSession = async () => {
          // Verifica si la sesión ya está activa antes de establecerla
          try {
              const response = await axios.get('https://app.celsospv.com/api/check_session', { withCredentials: true });
              console.log('Session is active:', response.data);
              setSessionActive(true);
              fetchImages(false); // Cargar imágenes si la sesión está activa
          } catch (error) {
              console.error('No active session:', error);
              // Si no hay sesión activa, intenta establecer una
              try {
                  const response = await axios.get('https://app.celsospv.com/api/set_session', { withCredentials: true });
                  console.log('Session set:', response.data);
                  setSessionActive(true);
                  fetchImages(false); // Puedes volver a cargar las imágenes
              } catch (setSessionError) {
                  console.error('Error setting session:', setSessionError);
              }
          }
      };
      

      const checkDialogue = async () => {
        if (JSON.parse(sessionStorage.getItem("open")) === null || sessionStorage.getItem("open") === "true"){
          console.log("value: ", sessionStorage.getItem("open"))
          sessionStorage.setItem("open", true)
          handleOpen()
          console.log("es la primera vez que se renderiza, he puesto el valor de open a: ", true)
          console.log("value: ", sessionStorage.getItem("open"))
        }
        else {
          sessionStorage.setItem("open", open)
          console.log("el valor era: ", open)
          

        }
      };
 
      checkSession();
      checkDialogue();
    // fetchImages(false); // Load initial images from Modules
  }, []);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked); // Cambia el estado al marcar o desmarcar
  };

  const handleFilesSelect = async (selectedFiles) => {
    try {
      setUploading(true);

      await new Promise(resolve => setTimeout(resolve, 2000));

      const formData = new FormData();
      Array.from(selectedFiles).forEach((file, index) => {
        formData.append('files', file);
      });

      const response = await axios.post('https://app.celsospv.com/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        withCredentials: true,
      });

      fetchImages(false); // Fetch images from Modules after upload
      setUploading(false);
    } catch (error) {
      console.error('Error uploading files:', error);
      setErrorMessage('Error uploading files. Please try again.');
      setUploading(false);
    }
  };

  const fetchImages = async (printed) => {
    try {
      // const endpoint = printed ? 'https://app.celsospv.com/api/Printed_modules' : 'https://app.celsospv.com/api/Modules';
      const response = await axios.get('https://app.celsospv.com/api/Printed_modules', {withCredentials: true});
      console.log("images from backend: ", response.data)
      setImages(response.data.map(image => `${image.url}?timestamp=${new Date().getTime()}`)); // Prevent caching
    } catch (error) {
      console.error('Error fetching images:', error);
      setErrorMessage('Error fetching images. Please try again.');
    }
  };

  const handleSaveCriteria = async (formValues) => {
    setFormData(formValues);

    try {
      const response = await axios.post('https://app.celsospv.com/api/save_criteria', formValues);
    } catch (error) {
      console.error('Error saving criteria:', error);
      setErrorMessage('Error saving criteria. Please try again.');
    }
  };

  const handleFindDefects = async () => {
    try {
      setScanning(true);
      await new Promise(resolve => setTimeout(resolve, 2000));

      const response = await axios.post('https://app.celsospv.com/api/find_defects', {}, {withCredentials: true});

      setScanning(false);
      fetchImages(true); // Fetch images from Printed_modules after analysis
    } catch (error) {
      console.error('Error finding defects:', error);
      setErrorMessage('Error finding defects. Please try again.');
      setScanning(false);
    }
  };

  const downloadImages = async () => {
    try {
      const response = await axios.get('https://app.celsospv.com/api/download_zip', {
        responseType: 'blob', withCredentials: true
      });
      
      console.log("data: ", response.data)
      // Create a link element, use it to download the zip file
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
      console.log(url)
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'images.zip'); // Name the downloaded file
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading images:', error);
      setErrorMessage('Error downloading images. Please try again.');
    }
  };

  return (
    <ThemeProvider theme={Theme}>
      <div className="AppBar">
        <TopBar />
      </div>
      <Modal
        open={open}
        onClose={(event, reason) => {
    if (reason !== 'backdropClick') {
      handleClose();
    }
  }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-box">
          <Typography 
            color="primary" 
            sx={{ 
              fontWeight: 'bold', 
              textAlign: 'center', 
              fontSize: '150%', 
              fontFamily: 'silkaregular'
              }}>
            Welcome to the pre-alpha version of our web application!
          </Typography>
            <Typography color="white" sx={{ marginTop: '15px', fontSize: '100%', fontFamily: 'silkaregular'}}>
            We would like to inform you that you are using an early version of our product that is still in development. Thank you for your understanding, 
            as there's much room for improvement. Please note that the features you see in this phase may change and may work differently in the final 
            release of the app. Stay tuned as there's major improvements coming soon!
          </Typography>
          <Typography color="white" sx={{ marginTop: '10px', fontSize: '100%', fontFamily: 'silkaregular'}}>
            We appreciate your feedback and support as we work to provide you with the best possible experience.
          </Typography>
          <Typography color="white" sx={{ marginTop: '10px', fontSize: '100%', fontFamily: 'silkaregular'}}>
            Additionally, all users with early access are prohibited from distributing this app without the consent of CELSOS.
          </Typography>
          <div className="center">
            {/* Checkbox con un label */}
            <FormControlLabel
              control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} />}
              label="I understand and accept the terms"
              sx={{ color: 'white', marginTop: '20px' }}
            />
            {/* Botón que se activa solo si se marca el checkbox */}
            <Button
              variant="contained"
              onClick={handleClose}
              disabled={!isChecked} // Botón deshabilitado si el checkbox no está marcado
              sx={{ marginTop: '20px' }}
            >
              Accept
            </Button>
          </div>
          
        </Box>
      </Modal>
      <div className="App">
        <RandomTextEffect text="Electroluminescence" />
        {images.length > 0 ? (
          <div className="images-container">
            <div className="button-column">
              <div className={`vertical-slide ${uploading ? 'uploading-animation' : ''}`}>
                <InputFileUpload setFiles={handleFilesSelect} />
              </div>

              <div className='spawn-analyze'>
                <Typography color="white" sx={{ fontSize: '120%', fontFamily: 'silkaregular', marginTop: '15%' }}>
                  Find defects in the modules!
                </Typography>
                <Button
                  component="label"
                  variant="contained"
                  tabIndex={-1}
                  sx={{ fontSize: '120%', fontFamily: 'silkaregular' }}
                  onClick={handleFindDefects}
                >
                  Find defects
                </Button>
              </div>

              <div className='spawn-save'>
                <Typography color="white" sx={{ fontSize: '120%', fontFamily: 'silkaregular', marginTop: '15%' }}>
                  Save your analyzed images!
                </Typography>
                <Button
                  component="label"
                  variant="contained"
                  tabIndex={-1}
                  sx={{ fontSize: '120%', fontFamily: 'silkaregular' }}
                  onClick={downloadImages}  // Call the new download function
                >
                  Save Images
                </Button>
              </div>
            </div>

            <div className='spawn-carrusel'>
              <div className="image-carousel-container">
                <ImageCarousel images={images} scanning={scanning} />
              </div>
            </div>
          </div>
        ) : (
          <div className="Body">
            <div className={`vertical-slide ${uploading ? 'uploading-animation' : ''}`}>
              <InputFileUpload setFiles={handleFilesSelect} />
            </div>
          </div>
        )}

        {uploading && (
          <Box sx={{ width: '50%', margin: 'auto', marginTop: '90px' }}>
            <LinearProgress color="primary" />
          </Box>
        )}

        {errorMessage && (
          <Typography variant="body1" color="error" sx={{ textAlign: 'center', marginTop: '20px' }}>
            {errorMessage}
          </Typography>
        )}
      </div>
    </ThemeProvider>
  );
}

export default App;
