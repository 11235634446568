// Copyright (C) CELSOS. All rights reserved.
import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const RoundedTypography = ({ borderRadius, padding, backgroundColor, fontSize, children }) => {
  return (
    <Box
    sx={{
        borderRadius: borderRadius || '10px',
        backgroundColor: backgroundColor || '#f0f0f0',
        padding: padding || '10px',
        display: 'inline-block',
        fontSize: fontSize || 'inherit', // Default value if not provided
        fontFamily:'silkaregular',
      }}
    >
      <Typography color="primary" fontSize={fontSize}>{children}</Typography>
    </Box>
  );
};

export default RoundedTypography;